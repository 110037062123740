<template>
  <div id="HomeBusinessInfoComponent" style="padding-top: 20px; padding-bottom: 8px;">
<!--    <div style="display: flex; color: #A1A1A1;" v-if="business">-->
<!--      <div-->
<!--          style="display:flex; flex: 1; color: #222222; margin-left: 8px; align-items: center; place-content: center;">-->
<!--        <div v-for="(item, index) in business.trustList" :key="index" style="display: flex;"-->
<!--             :style="{ marginLeft: index !== 0 ? `10px` : `0` }">-->
<!--          <img src="../assets/images/icon_trust.png" style="width: 18px; height: 18px;"/>-->
<!--          <div style="line-height: 18px;">{{ '认真' }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="box" style="margin: 0;">
      <div style="color: #222222; font-weight: bold;">
        <div style="display: flex;">
          <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
          <div style="line-height: 18px; margin-left: 5px;">企业简介</div>
        </div>
        <van-image width="100%" :src="business ? business.imageUrl : ''" style="margin-top: 8px;"/>
        <div style="color: #222222; font-size: 14px; margin-top: 8px; font-weight: normal;">{{
            business.introduction
          }}
        </div>
      </div>
    </div>
    <div class="box">
      <div style="color: #222222; font-weight: bold;">
        <div style="display: flex;">
          <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
          <div style="line-height: 18px; margin-left: 5px;">企业荣誉</div>
        </div>
        <div v-for="(item, index) in business.list" :key="index">
          <van-image width="100%" :src="item" style="margin-top: 8px;"/>
        </div>

      </div>

    </div>
    <div class="box">
      <div style="color: #222222; font-weight: bold; margin-bottom: 12px;">
        <div style="display: flex;">
          <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
          <div style="line-height: 18px; margin-left: 5px;">定位信息</div>
        </div>
        <div id="businessMap" style="width: 100%; height: calc(320px / 16 * 9); margin-top: 8px;"></div>

        <div style="display: flex; color: #222222; font-weight: normal; margin-top: 8px;">
          <div style="width: 42px;">{{ '地址:' }}</div>
          <div style="flex: 1;">{{ business.address }}</div>
        </div>

      </div>
    </div>
    <div class="box">
      <div style="display: flex;">
        <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
        <div style="line-height: 18px; margin-left: 5px; font-weight: bold">区块链证书展示</div>
      </div>

      <div style="display: flex; margin-top: 4px; place-content: center; color: #222222;">
        <van-image :src="business ? business.supplierContractUrl : ''" alt=""
                   style="width: 160px; height: 240px;"/>
      </div>

      <div style="color: #A1A1A1; margin-top: 8px;">
        <div style="display: flex; margin-top: 4px;">
          <div style="width: 56px;">唯一标识</div>
          <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right; word-break: break-all;">
            {{ business.supplierContractId }}
          </div>
        </div>
        <div style="display: flex; margin-top: 4px;">
          认证时间
          <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">
            {{ business.supplierDigitalIdentityReportTime }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import vPlayBack from 'v-playback'
import { Image as VanImage} from "vant";
Vue.use(VanImage)

Vue.use(vPlayBack)

export default {
  name: "HomeBusinessInfoComponent",
  props: [
    'business'
  ],
  data() {
    return {
      data: {
        title: '出品企业',

        map: null,
      }
    }
  },
  methods: {
    init() {
      console.log(this.business)
      if (this.map) {
        return;
      }

      setTimeout(() => {
        this.initMap();// 渲染地图
      }, 500);
    },
    initMap() {

      let latitude = Number(this.business.latitude);
      let longitude = Number(this.business.longitude);

      console.log("initBusinessMap = " + latitude + " ; " + longitude);

      if (this.businessMap) {
        this.businessMap.center = new TMap.LatLng(latitude, longitude);
        this.businessMarkerLayer.updateGeometries([
          {
            "styleId": "myStyle",
            "id": "111",
            "position": new TMap.LatLng(latitude, longitude),
            "properties": {//自定义属性
              "title": "marker1"
            }
          }
        ])

        return;
      }

      console.log("initBusinessMap = " + latitude + " ; " + longitude);

      const center = new TMap.LatLng(latitude, longitude);  //设置地图中心点坐标
      this.businessMap = new TMap.Map("businessMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        }
      });

      //创建并初始化MultiMarker
      this.businessMarkerLayer = new TMap.MultiMarker({
        map: this.businessMap,  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": {x: 16, y: 32}
          })
        },
        //点标记数据数组
        geometries: [{
          "id": "111",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": 'myStyle',  //指定样式id
          "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
          "properties": {//自定义属性
            "title": "marker1"
          }
        }
        ]
      });

    },

  }
}
</script>

<style lang="less" scoped>
.box {
  background: #fff;
  border-radius: 6px;
  padding: 10px;
  &+.box{
    margin-top: 16px;
  }
  .title{
    padding-left: 25px;
    position: relative;
    margin-bottom: 8px;
    &::after,&::before{
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background: #64b65b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 2;
    }
    &::after{
      background: #cce8cb;
      z-index: 1;
      left: 4px;
      top: 1px;
    }
  }
}
</style>