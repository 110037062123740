<template>
  <div id="homeProductTraceComponent" style="padding-top: 20px; padding-bottom: 8px;">
    <div class="box">
      <div class="title" style="color: #222222; font-size: 14px; font-weight: bold;">{{ data.title }}</div>

      <van-image width="100%" height="66vw" :src="sourceImage"  />

      <div style="color: #222222; font-size: 14px; margin-top: 8px;">{{ "\t" + sourceIntroduction }}</div>
    </div>

    <div class="box" v-if="sourcePlayAddressUrl"> 
      <div class="title" style="color: #222222; font-size: 14px; font-weight: bold;">
        {{
          '原产地视频'
        }}
      </div>

      <v-playback :url="data.url" v-if="data.play"></v-playback>

      <img alt="" src="../assets/images/bg_play_default.png" style="height: 56vw; width: 100%;" v-if="!data.play"
        @click="onClickPlay" />
    </div>

    <div class="box">
      <div class="title" style="color: #222222; font-size: 14px; font-weight: bold; ">{{ '定位信息' }}</div>

      <div id="container" style="width: 100%; height: 32vh;"></div>

      <div style="display: flex; color: #222222; margin-top: 8px;">
        <div style="width: 42px;">{{ '地址:' }}</div>
        <div style="flex: 1;">{{ sourceAddress }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import vPlayBack from 'v-playback'
import { Image as VanImage} from "vant";
Vue.use(VanImage)
Vue.use(vPlayBack)

export default {
  name: "HomeProductTraceComponent",
  props: [
    'sourceImage', 'sourceIntroduction', 'sourceAddress', 'sourcePlayAddressUrl', 'latitude', 'longitude'
  ],
  data() {
    return {
      data: {
        title: '原产地简介',

        map: null,

        url: '',
        autoPlay: false,
        play: false,
        // url: 'https://vide.fsytss.com/ImportVideo_799692.911942%20%281%29.mp4'
      }
    }
  },
  methods: {
    init() {
      if (this.map) {
        return;
      }

      setTimeout(() => {
        this.initMap();// 渲染地图
      }, 500);
    },
    initMap() {

      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);

      // console.log(latitude)
      // console.log(longitude)

      // latitude = 37.86236
      // longitude = 113.58762

      const center = new TMap.LatLng(latitude, longitude);  //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        }
      });
      // // 手绘地图渲染,主要个性化手绘地图 参考文章2.个性化手绘地图
      // this.mapLayer();
      // // 图标和气泡窗的显示 参考文章3.地图标点信息+图标
      // this.multiMarker();
      // this.infoWindow();

      //创建并初始化MultiMarker
      var markerLayer = new TMap.MultiMarker({
        map: this.map,  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 16, y: 32 }
          })
        },
        //点标记数据数组
        geometries: [{
          "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": 'myStyle',  //指定样式id
          "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
          "properties": {//自定义属性
            "title": "marker1"
          }
        }
        ]
      });

    },

    onClickPlay() {
      this.data.play = true;
      this.data.url = this.sourcePlayAddressUrl;
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  background: #fff;
  border-radius: 6px;
  padding: 10px;
  &+.box{
    margin-top: 16px;
  }
  .title{
    padding-left: 25px;
    position: relative;
    margin-bottom: 8px;
    &::after,&::before{
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      background: #64b65b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 2;
    }
    &::after{
      background: #cce8cb;
      z-index: 1;
      left: 4px;
      top: 1px;
    }
  }
}
</style>