<template>
     <div class="areaLine" id="areaCanvasImg">
      <div :class="isred==true?'clickRedText':'clickDownLaod'" @click="imagePreview()">点击放大 ></div>
      <div class="areaContainer" >
        <div class="areaChid" ref="canvasImg">
          <img v-if="imgtype==0"  src="../assets/images/project1/areaRedOrder.png" alt=""  />
          <img v-else-if="imgtype==1"  src="../assets/images/project1/areaRedBusiness.png" alt=""  />
          <img v-else-if="imgtype=2"  src="../assets/images/project1/areaBlueOrder.png" alt=""  />
          <img v-else-if="imgtype==3"  src="../assets/images/project1/areaBlueBusiness.png" alt=""  />
          <img v-else  src="../assets/images/project1/areaRedOrder.png" alt=""  />

          <!-- <img  :src="data.productThumbnailImageUrl + '?' + new Date().getTime()" alt="" crossorigin="https://asset.fsytss.com"  /> -->
          <!-- <div  crossOrigin="anonymous" class="img" :style="{ backgroundImage: `url(${data.productThumbnailImageUrl})` }"> -->
          <!-- <div  class="img" > -->
          <div   class="img" >  
            <div class="firstLine">海南省文昌市畜牧兽医服务中心</div>
            <div class="secondLine">{{ id }}</div>
            <div class="thirdLine">{{ time}}</div>
          </div>
          <!-- </div> -->

        </div>
          
      </div>
    </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview, Loading } from "vant";
import html2canvas from "html2canvas";
Vue.use(ImagePreview).use(Loading);
export default {
    name: "areaCanvasImg",
    props: {
        isred:Boolean,
        imgtype:Number,
        id:String,
        time:String
    },
    data() {
        return {
          data:{
           

          },
          
          loadingAll: false,
        };
    },
    mounted() {
        
    },
    methods: {
        async createImage() {
          try {
            // const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
            // const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
            // this.imgUrl = image;
            // ImagePreview([image]);

            var canvas2 = document.createElement("canvas");
            let _canvas = this.$refs.canvasImg;
            var w = parseInt(window.getComputedStyle(_canvas).width);
            var h = parseInt(window.getComputedStyle(_canvas).height);
            //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
            canvas2.width = w * 2;
            canvas2.height = h * 2;
            canvas2.style.width = w + "px";
            canvas2.style.height = h + "px";
            const context = canvas2.getContext("2d");
            context.scale(1,1);
            context.fillStyle = '#FFFFFF'
            context.fillRect(0, 0, canvas2.width, canvas2.height);


            const config = {
            backgroundColor: null,
            canvas: canvas2,
            useCORS: true,//配置允许跨域
            scale: 2,
            dpi: 800 // 处理模糊问题
            };
            console.log("获取指定的宽高", w, h, canvas2);

            const canvas = await html2canvas(this.$refs.canvasImg,config).then(canvas => {
            
            
            try {
                // 转成图片，生成图片地址
                this.imgUrl = canvas.toDataURL("image/png");  
                    ImagePreview([this.imgUrl]);
                } catch (e) {
                alert("图片跨域,保存失败");
                }


            });

            this.loadingAll = false;
          } catch (e) {
              console.log("绘制失败");
              this.loadingAll = false;
              throw new Error(e);
          }
        },

        imagePreview() {
        this.loadingAll = true;
        this.createImage();
        }
    }

};


</script>
<style lang="less" scoped>
.areaLine {
  //background-image: linear-gradient(to bottom, #fd955b, #c12920);
  width: 100vw;
  padding: 5px 27px 16px 27px;
  margin-top: -5px;
  // padding-bottom: 130px;
  // margin-bottom: -100px;

  .clickRedText{
    color: #d82a1f;
    font-size: 13px;
    // padding: 0 12px;
    text-align: right;
    margin-bottom: 7px;
    
  }
  .clickDownLaod {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-bottom: 8px;
    color: #fff;
  }
  .areaContainer {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    //position: relative;
    //height: calc((100vw - 28px * 2 - 9px * 2) / 1.402) ;
    //width: calc(100vw - 28px * 2 - 9px * 2);
    //overflow: hidden;

    .areaChid{

      position: relative;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 28px * 2 - 9px * 2);
      height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
    }
    .img {
      position: absolute;
      top: 0;
      left: 0;
      //width: calc(100vw - 28px * 2 - 9px * 2);
      //height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
      // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_red_order.png") no-repeat
      //   center / cover;
      //background: url("../../assets/images/project1/areaRedOrder.png") no-repeat
      //  center / cover;
      padding-top: 69px; //18.2
      padding-left: 90px;
      padding-right: 25px;
      font-size: 12px;
      word-break: break-all;
      color: #231815;
      text-align: right;
      line-height: 16px;
      .firstLine{
        width: 200px;
        position: absolute;
        top: 69px;
        left: 80px;
      }
      .secondLine {
        width: 190px;
        position: absolute;
        top: 90px;
        left: 90px;
      }
      .thirdLine{
        width: 200px;
        position: absolute;
        top: 174px;
        left: 80px;
      }
    }
  }
}
</style>